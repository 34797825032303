import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About</h1>
      <p>DeFi Defenders is a free mint open edition series by Carlo⚖.️eth. This collection has no utility or roadmap. It's a Web3 community united around crypto and blockchain law. It is our belief that lawyers are the guardians of the blockchain and vitally important to the continued growth and adoption of crypto.</p>
      <p>This collection is open to lawyers, law students and anyone else who understands and supports the important role that the law plays in the future of crypto and blockchain regulation.</p>
      <p>Carlo is a criminal defense lawyer, former law professor and advocate for blockchain tech. Carlo is also the co-host of LexLine, a weekly Twitter Space and Podcast show that discusses the latest developments in Web3, NFT, crypto and blockchain law.</p>
      <p style={{textAlign: "center"}}>More from Carlo and DeFi Defenders:</p> 
      <p></p>
      {/* <div className='link-container'>
        <a href="https://twitter.com/DeFiDefenseLaw" target="_blank" rel="noopener noreferrer">Twitter </a><p>|</p><a href="https://linktr.ee/carlodangelo" target="_blank" rel="noopener noreferrer">Linktree</a>
      </div> */}
    </div>
  );
};

export default About;