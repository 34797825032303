import React  from 'react';
import './LinkTree.css';

function LinkTree() {

  return (
            <div className='container'>
              <div className='visit-my-substack-container'>
                  <a href="https://linktr.ee/carlodangelo" target="_blank" rel="noopener noreferrer" className='link-container'>
                    <h3>LinkTree</h3>
                  </a>
              </div>
            </div>
  );
 
}

export default LinkTree;
