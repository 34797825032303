import React from 'react';
import './HeaderLogo.css';

function HeaderLogo() {
  return (
    <div className="header-logo-container">
      <img className="header-logo-image" src="static/outline_white.png" alt="DeFi Defenders Logo" />
      <span className="header-logo-text">DeFi Defenders</span>
    </div>
  );
}

export default HeaderLogo;
