import React  from 'react';
import './MintOnManifold.css';

function MintOnManifold() {

  return (
            <div className='container'>
              <div className='visit-my-substack-container'>
                  <a href="https://app.manifold.xyz/c/DeFiDefenders" target="_blank" rel="noopener noreferrer" className='link-container'>
                    <h3>Mint on Manifold</h3>
                  </a>
              </div>
            </div>
  );
 
}

export default MintOnManifold;
