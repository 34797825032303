import React, { useState } from 'react';
import axios from 'axios';
import './CreateGifButton.css'

function CreateGifButton({ onImageProcessed, lastUploadedFile, setIsProcessing }) {
 const handleGifCreate = async () => {
            if (lastUploadedFile) {
                setIsProcessing(true); // Start the processing animation
                const formData = new FormData();
                formData.append('file', lastUploadedFile);
                try {
                    const requesturl = `https://defi-defenders-ziqga.ondigitalocean.app/createGif`;
                    const response = await axios.post(requesturl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob',
                    });
                    const url = URL.createObjectURL(response.data);
                    onImageProcessed(url);
                } catch (error) {
                    console.error("Error uploading and fetching the image:", error);
                } finally {
                    setIsProcessing(false);
                }
            } else {
                alert('Please upload an image first.');
            }
        };


    return (
        <div>
            <button className='shuffle-button' onClick={handleGifCreate}>
                Create gif
            </button>
        </div>
    );
}

export default CreateGifButton;


