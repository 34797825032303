import React  from 'react';
import './TwitterButton.css';

function TwitterButton() {

  return (
            <div className='container'>
              <div className='visit-my-substack-container'>
                  <a href="https://twitter.com/DeFiDefenseLaw" target="_blank" rel="noopener noreferrer" className='link-container'>
                    <h3>Twitter/X</h3>
                  </a>
              </div>
            </div>
  );
 
}

export default TwitterButton;
