import React  from 'react';
import './EmailPopup.css';

function EmailPopup() {

  return (
            <div className='container'>
              {/* <p className='for-more-info'>For more insights from Defi Defenders:</p> */}
              <div className='visit-my-substack-container'>
                  <a href="https://defidefenders.substack.com/" target="_blank" rel="noopener noreferrer" className='link-container'>
                    <h3>Substack </h3>
                    {/* <img className='substack-logo' src='https://camo.githubusercontent.com/3d4121e2061fe8a37e532bc8485e2ef0f8dbfaa26fe0f7e53f755df91d8a4333/68747470733a2f2f737562737461636b2e636f6d2f696d672f737562737461636b2e706e67' /> */}
                  </a>
              </div>
            </div>
  );
 
}

export default EmailPopup;
