import React, { useState } from 'react';
import axios from 'axios';
import './FileUpload.css'
import DownloadImageButton from '../DowloadImageButton/DownloadImageButton';
import ShuffleColorsButton from '../ShuffleColorsButton/ShuffleColorsButton';
import CreateGifButton from '../CreateGifButton/CreateGifButton';

function ImageUploadComponent({ lastUploadedFile, setLastUploadedFile, onImageProcessed , setIsProcessing}) {
    const fileInputRef = React.useRef(null); // Create a ref to the file input

    const handleImageUpload = async (event) => {
        setIsProcessing(true); // Start the processing animation
        const file = event.target.files[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!validTypes.includes(file.type)) {
            alert('Invalid file type');
            return;
        }
        if (file.size > 2500000) { // 2MB
            alert('File size too large');
            return;
        }
        setLastUploadedFile(file);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const requesturl = `https://defi-defenders-ziqga.ondigitalocean.app/upload`;
            const response = await axios.post(requesturl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            });
            const url = URL.createObjectURL(response.data);
            onImageProcessed(url);
        } catch (error) {
            console.error("Error uploading and fetching the image:", error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <label className='image-upload-button'>
            Generate Palette 
            <input ref={fileInputRef} type="file" style={{display:'none'}} onChange={handleImageUpload} />
        </label>
    );
}


function ImageDisplayComponent({ imageSrc }) {
    return imageSrc ? <img src={imageSrc} className="myImage" alt="Processed Image" /> : null;
}

function ImageUploadAndDisplay() {
  const [imageSrc, setImageSrc] = useState("/static/outline_white.png");
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);

    
  return (
    <div className='image-upload-and-display'>
      <div className="pulsating-dot-container">
            <ImageDisplayComponent imageSrc={imageSrc} />
            {isProcessing && <div className="pulsating-dot"></div>}
      </div>
        <div className='image-upload-and-shuffle-container'>
            <ImageUploadComponent lastUploadedFile={lastUploadedFile} setLastUploadedFile={setLastUploadedFile} onImageProcessed={setImageSrc} setIsProcessing={setIsProcessing} />
            <ShuffleColorsButton onImageProcessed={setImageSrc} lastUploadedFile={lastUploadedFile} setIsProcessing={setIsProcessing}/>
            <DownloadImageButton imageSrc={imageSrc}/>
            <CreateGifButton  onImageProcessed={setImageSrc} lastUploadedFile={lastUploadedFile} setIsProcessing={setIsProcessing}/>            
        </div>
    </div>
  );
}

export default ImageUploadAndDisplay;