import React, {useState} from 'react';
import './ShuffleColorsButton.css';
import axios from 'axios';

function ShuffleColorsButton({ onImageProcessed, lastUploadedFile, setIsProcessing }) {

    const handleShuffle = async () => {
            if (lastUploadedFile) {
                setIsProcessing(true); // Start the processing animation
                const formData = new FormData();
                formData.append('file', lastUploadedFile);
                try {
                    const requesturl = `https://defi-defenders-ziqga.ondigitalocean.app/upload`;
                    const response = await axios.post(requesturl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob',
                    });
                    const url = URL.createObjectURL(response.data);
                    onImageProcessed(url);
                } catch (error) {
                    console.error("Error uploading and fetching the image:", error);
                } finally {
                    setIsProcessing(false);
                }
            } else {
                alert('Please upload an image first.');
            }
        };


    return (
        <div>
            <button className='shuffle-button' onClick={handleShuffle}>
                Shuffle
            </button>
        </div>
    );
}

export default ShuffleColorsButton;

