import "./DownloadImageButton.css"

function DownloadImageButton({ imageSrc }) {
  if (!imageSrc) return null; // Don't render the button if there's no image

  return (
    <a href={imageSrc} download>
      <button>Download</button>
    </a>
  );
}

export default DownloadImageButton