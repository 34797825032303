import React, { useState } from 'react';
import ImageUploadAndDisplay from './components/FileUpload/FileUpload';
import DownloadImageButton from './components/DowloadImageButton/DownloadImageButton';
import EmailPopup from './components/EmailPopup/EmailPopup';
import HeaderLogo from './components/HeaderLogo/HeaderLogo';
import './App.css';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import MintOnManifold from './components/MintOnManifoldButton/MintOnManifold';  
import TwitterButton from './components/TwitterButton/TwitterButton';
import LinkTree from './components/LinkTree/LinkTree';

function App() {
    const [imageSrc, setImageSrc] = useState(null);

    const handleFileUpload = (src) => {
        setImageSrc(src);
    };

    return (
        <div className="app">
            <HeaderLogo />
            <ImageUploadAndDisplay onImageProcessed={handleFileUpload} />
            <About />
            <div className='container-of-container-lol'>
                <div className="button-link-container">
                    <MintOnManifold/>
                    <LinkTree />
                    <EmailPopup />
                    <TwitterButton />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;